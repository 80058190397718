import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { PersistGate } from 'redux-persist/integration/react';
import { IntlProvider } from './Utils/reactIntl';
import { persistor, store } from './Redux/store';
import RoutesWrapper from './Router';
import English from './Lang/en.json';
import French from './Lang/fr.json';
import Constants from './Helpers';
import './Assets/styles/globals.css';
import { ToastContainer } from 'react-toastify';

const locale = navigator.language;
// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#2B2B2B',
    },
    secondary: {
      main: '#7CFBD9',
    },
  },
});
let lang: any;
if (locale === Constants.LOCALE_ID.en) {
  lang = English;
} else if (locale === Constants.LOCALE_ID.fr) {
  lang = French;
}
function App() {
  return (
    <IntlProvider locale={locale} messages={lang}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <ToastContainer />
            <BrowserRouter>
              <RoutesWrapper />
            </BrowserRouter>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </IntlProvider>
  );
}
export default App;
