import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialStateTypes } from 'src/Redux/Types/auth';

const initialState: initialStateTypes = {
  authToken: '',
  isLoggedIn: false,
  userInfo: null,
  isSidebarOpen: true,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateSidebar: (state, action: PayloadAction<{ status: boolean }>) => {
      state.isSidebarOpen = action.payload.status;
    },
  },
});

export const { updateSidebar } = authSlice.actions;
export default authSlice.reducer;
