//@ts-ignore
import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  dataBlog: [],
  total: 0,
  pageNo: 0,
  limit: 0,
  totalPage: 0,
  loading: false,
  blogDetail: {},
};
const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    loaderListner: (state: any, action: any) => {
      state.loading = action.payload.loading;
    },
    blogListner: (state: any, action: any) => {
      state.loading = action.payload.loading;
      state.dataBlog = action.payload.dataBlog;
      state.total = action.payload.total;
      state.limit = action.payload.limit;
      state.pageNo = action.payload.pageNo;
      state.totalPage = action.payload.totalPage;
    },
    blogDetailListner: (state: any, action: any) => {
      state.blogDetail = action.payload.data;
    },
  },
});
export const { loaderListner, blogListner, blogDetailListner }: any = blogSlice.actions;

export default blogSlice.reducer;
