import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { UserFilterModel } from '../../Utils/model';

const initialState: UserFilterModel = {
  loading: true,
  error: '',
  total: 0,
  pageNo: 0,
  limit: 0,
  totalPage: 0,
  filterKeys: {},
};
const ApplyFilterSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    filter: (state: any, action: any) => {
      state.filterData = action.payload.filterData;
      state.total = action.payload.total;
      state.limit = action.payload.limit;
      state.pageNo = action.payload.pageNo;
      state.totalPage = action.payload.totalPage;
    },
  },
});

// Action creators are generated for each case reducer function
export const { filter }: any = ApplyFilterSlice.actions;

export default ApplyFilterSlice.reducer;
