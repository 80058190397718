//@ts-ignore
import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  data: [],
  loading: false,
};
const legalContentSlice = createSlice({
  name: 'legalContent',
  initialState,
  reducers: {
    loaderListner: (state: any, action: any) => {
      state.loading = action.payload.loading;
    },
    legalContentListner: (state: any, action: any) => {
      state.data = action.payload.data;
    },
  },
});
export const { loaderListner, legalContentListner }: any = legalContentSlice.actions;

export default legalContentSlice.reducer;
