import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import forgotPasswordSlice from 'src/Modules/Auth/forgotPassword.slice';
import userListSlice from 'src/Modules/UserManagement/userManagement.slice';
// We'll use redux-logger just as an example of adding another middleware
import logger from 'redux-logger';
import authSlice from './Features/AuthSlice';
import blockUserSlice from 'src/Modules/UserManagement/blockUser.slice';
import propertySlice from 'src/Modules/PropertyManagement/AddProperty/property.slice';
import userdetailSlice from 'src/Modules/UserManagement/UserDetail/userDetail.slice';
import propertyDetailSlice from 'src/Modules/PropertyManagement/PropertyDetail/detail.slice';
import applyFilterSlice from 'src/Modules/UserManagement/userFilter.slice';
import loginSlice from 'src/Modules/Auth/login.slice';
import userWalletSlice from 'src/Modules/UserWallet/userWallet.slice';
import propertyWalletSlice from 'src/Modules/PropertyWallet/propertyWallet.slice';
import spvSlice from 'src/Modules/PropertyManagement/ViewTransaction/transaction.slice';
import externalAccountSlice from 'src/Modules/ExternalAccount/externalAccount.slice';
import justSliceThingSlice from 'src/Modules/CMSManagement/JustSliceThing/justSliceThing.slice';
import couponManagementSlice from 'src/Modules/CouponManagement/couponManagement.slice';
import sliceAmbassadorSlice from 'src/Modules/CMSManagement/SliceAmbassador/sliceAmbassador.slice';
import perksOfInvestmentSlice from 'src/Modules/CMSManagement/PerkInvestment/perksOfInvestment.slice';
import rewardsAndGrowMoreSlice from 'src/Modules/CMSManagement/RewardsAndGrowMore/rewardsAndGrowMore.slice';
import loginScreenSlice from 'src/Modules/CMSManagement/LoginScreen/loginScreen.slice';
import legalContentSlice from 'src/Modules/CMSManagement/LegalContent/legalContent.slice';
import iconsSlice from 'src/Modules/CMSManagement/Icons/icons.slice';
import sliceWalletSlice from 'src/Modules/SliceWallet/sliceWallet.slice';
import faqSlice from 'src/Modules/CMSManagement/Faqs/faq.slice';
import newsSlice from 'src/Modules/CMSManagement/News/news.slice';
import blogSlice from 'src/Modules/CMSManagement/Blog/blog.slice';
import learnToInvestSlice from 'src/Modules/CMSManagement/LearnToInvest/learnToInvest.slice';
import supportSlice from 'src/Modules/CMSManagement/Support/support.slice';
import dashboardSlice from 'src/Modules/Dashboard/dashboard.slice';
import profileSlice from 'src/Modules/Profile/profile.slice';
import rolesSlice from 'src/Modules/RolesManagement/roles.slice';
import Filters from './Features/Filters';

const persistConfig = {
  debug: false,
  key: 'root',
  keyPrefix: 'v.1',
  storage,
  blacklist: [],
  // add reducer name to persist
  whitelist: [
    'auth',
    'login',
    'userdetail',
    'propertydetail',
    'externalAccount',
    'justSliceThing',
    'faq',
    'news',
    'blogs',
    'role',
    'sliceAmbassador',
    'perksOfInvestment',
    'rewardsAndGrowMore',
    'loginScreen',
    'legalContent',
    'icons',
    'learnToInvest',
  ],
};

// combine all reducers here
const rootReducer = combineReducers({
  auth: authSlice,
  login: loginSlice,
  forgetPassword: forgotPasswordSlice,
  userList: userListSlice,
  blockUser: blockUserSlice,
  propertyManagement: propertySlice,
  userdetail: userdetailSlice,
  propertydetail: propertyDetailSlice,
  filteredUserList: applyFilterSlice,
  userWallet: userWalletSlice,
  sliceWallet: sliceWalletSlice,
  propertyWallet: propertyWalletSlice,
  spvAccount: spvSlice,
  externalAccount: externalAccountSlice,
  justSliceThing: justSliceThingSlice,
  couponManagementSlice: couponManagementSlice,
  sliceAmbassador: sliceAmbassadorSlice,
  perksOfInvestment: perksOfInvestmentSlice,
  rewardsAndGrowMore: rewardsAndGrowMoreSlice,
  loginScreen: loginScreenSlice,
  legalContent: legalContentSlice,
  icons: iconsSlice,
  faq: faqSlice,
  news: newsSlice,
  blog: blogSlice,
  learnToInvest: learnToInvestSlice,
  support: supportSlice,
  dashboard: dashboardSlice,
  profile: profileSlice,
  role: rolesSlice,
  filters: Filters,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(logger),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
