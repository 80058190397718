import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { UserDetail } from 'src/Utils/model';

const initialState: UserDetail = {
  data: [],
  idVerificationData: [],
  amlStatus: '',
  addressVerificationData: [],
  loading: false,
  transactionDetailData: [],
  kycHistory: [],

};
const userdetailSlice = createSlice({
  name: 'userdetail',
  initialState,
  reducers: {
    userDetailLoading: (state: UserDetail, action: PayloadAction<UserDetail>) => {
      state.loading = action.payload.loading;
    },
    detail: (state: UserDetail, action: PayloadAction<UserDetail>) => {
      state.data = action.payload.data;
    },

    idVerificationSlice: (state: UserDetail, action: PayloadAction<any>) => {
      state.idVerificationData = action.payload.data;
    },
    addressVerificationSlice: (state: UserDetail, action: PayloadAction<any>) => {
      state.addressVerificationData = action.payload.data;
    },
    amlCheckStatusSlice: (state: UserDetail, action: PayloadAction<any>) => {
      state.amlStatus = action.payload.data;
    },
    transactionDetailSlice: (state: UserDetail, action: PayloadAction<any>) => {
      state.transactionDetailData = action.payload.data;
    },
    kycHistorySlice: (state: UserDetail, action: PayloadAction<any>) => {
      state.kycHistory = action.payload.data;
    },

  },
});

// Action creators are generated for each case reducer function
export const {
  detail,
  idVerificationSlice,
  amlCheckStatusSlice,
  addressVerificationSlice,
  userDetailLoading,
  transactionDetailSlice,
  kycHistorySlice,
  
}: any = userdetailSlice.actions;

export default userdetailSlice.reducer;
