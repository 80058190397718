//@ts-ignore

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  data: [],
  error: null,
  total: 0,
  pageNo: 0,
  limit: 0,
  totalPage: 0,
  loading: false,
  userWalletBalance:0
};
const userWalletSlice = createSlice({
  name: 'userList',
  initialState,
  reducers: {
    loaderListner: (state: any, action: any) => {
      state.loading = action.payload.loading;
    },
    userWalletListner: (state: any, action: PayloadAction<any>) => {
      state.loading = action.payload.loading;
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.limit = action.payload.limit;
      state.pageNo = action.payload.pageNo;
      state.totalPage = action.payload.totalPage;
    },
    getUserWalletBalanceSlice: (state: any, action: PayloadAction<any>) => {
      state.userWalletBalance = action.payload.data;
    },
  },
});
export const { loaderListner, userWalletListner, getUserWalletBalanceSlice }: any =
  userWalletSlice.actions;

export default userWalletSlice.reducer;
