import { useSelector } from 'react-redux';
import PageNotFound from 'src/Components/PageNotFound';
import Constants from 'src/Helpers';

const AccessControlWrapper = ({ children, name, roleName }: any) => {
  const { login }: any = useSelector((state: any) => state);
  const { data } = login;
  const { permissions, userType } = data;

  if (userType === 'SUB_ADMIN') {
    // if (name === 'Dashboard') {
    //   const found = Object.keys(Constants.DASHBOARD_PERMISSION).some((r) =>
    //     permissions.includes(r)
    //   );
    //   if (found) {
    //     return children;
    //   }
    // },"roleName"
    if (roleName) {
      if (name === 'Coupon Management') {
        const found =
          permissions.includes('VIEW_GLOBAL_COUPONS') ||
          permissions.includes('VIEW_USER_SPECIFIC_COUPONS') ||
          permissions.includes(roleName);
        if (found) {
          return children;
        }
      } else {
        const found = permissions.includes(roleName);
        if (found) {
          return children;
        }
      }
    }
    if (name === 'Login') {
      return children;
    }
  } else {
    return children;
  }
  return <PageNotFound />;
};

export default AccessControlWrapper;
