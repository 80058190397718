//@ts-ignore
import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  data: [],
  loading: false,
};
const loginScreenSlice = createSlice({
  name: 'loginScreen',
  initialState,
  reducers: {
    loaderListner: (state: any, action: any) => {
      state.loading = action.payload.loading;
    },
    loginScreenListner: (state: any, action: any) => {
      state.data = action.payload.data;
    },
  },
});
export const { loaderListner, loginScreenListner }: any = loginScreenSlice.actions;

export default loginScreenSlice.reducer;
