//@ts-ignore
import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  data: [],
  total: 0,
  pageNo: 0,
  limit: 0,
  totalPage: 0,
  loading: false,
};
const rewardsAndGrowMoreSlice = createSlice({
  name: 'rewardsAndGrowMore',
  initialState,
  reducers: {
    loaderListner: (state: any, action: any) => {
      state.loading = action.payload.loading;
    },
    rewardsAndGrowMoreListner: (state: any, action: any) => {
      state.loading = action.payload.loading;
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.limit = action.payload.limit;
      state.pageNo = action.payload.pageNo;
      state.totalPage = action.payload.totalPage;
    },

  },
});
export const { loaderListner, rewardsAndGrowMoreListner }: any =
  rewardsAndGrowMoreSlice.actions;

export default rewardsAndGrowMoreSlice.reducer;
