import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  data: [],
  loading: false,
  propertyTransactionData: {
    data: [],
    error: null,
    total: 0,
    pageNo: 0,
    limit: 0,
    totalPage: 0,
    capitalAppreciation: 0,
    investers: 0,
    investmentAmount: 0,
    portfolioValue: 0,
    rentRecieved: 0,
    walletBalance: 0,
  },

  propertyManagementTransactionDetailData: {},
};
const spvSlice = createSlice({
  name: 'spvAccount',
  initialState,
  reducers: {
    loaderListner: (state: any, action: any) => {
      state.loading = action.payload.loading;
    },
    spv: (state: any, action: PayloadAction<any>) => {
      state.data = action.payload.data;
    },
    propertyTransactionData: (state: any, action: any) => {
      state.loading = action.payload.loading;
      state.propertyTransactionData.capitalAppreciation = action.payload.capitalAppreciation;
      state.propertyTransactionData.investers = action.payload.investers;
      state.propertyTransactionData.investmentAmount = action.payload.investmentAmount;
      state.propertyTransactionData.portfolioValue = action.payload.portfolioValue;
      state.propertyTransactionData.rentRecieved = action.payload.rentRecieved;
      state.propertyTransactionData.walletBalance = action.payload.walletBalance;
      state.propertyTransactionData.data = action.payload.data;
      state.propertyTransactionData.total = action.payload.total;
      state.propertyTransactionData.limit = action.payload.limit;
      state.propertyTransactionData.pageNo = action.payload.pageNo;
      state.propertyTransactionData.totalPage = action.payload.totalPage;
    },
    propertyManagementTransactionDetail: (state: any, action: PayloadAction<any>) => {
      state.propertyManagementTransactionDetailData = action.payload.data;
    },

  },
});

// Action creators are generated for each case reducer function
export const {
  spv,
  loaderListner,
  propertyTransactionData,
  propertyManagementTransactionDetail,
}: any = spvSlice.actions;

export default spvSlice.reducer;
