import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AddPropertyModel } from '../../../Utils/model';

const initialState: AddPropertyModel = {
  data: {},
};

const propertySlice = createSlice({
  name: 'propertyManagement',
  initialState,
  reducers: {
    createProperty: (state: AddPropertyModel, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    successPropertyListReducer: (state: any, action: PayloadAction<any>) => {
      state.loading = action.payload.loading;
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.limit = action.payload.limit;
      state.pageNo = action.payload.pageNo;
      state.totalPage = action.payload.totalPage;
    },
    fetchPropertyListner: (state: any) => {
      state.loading = true;
    },
    propertyStatusListner: (state: any) => {
      state.loading = true;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  createProperty,
  successPropertyListReducer,
  fetchPropertyListner,
  propertyStatusListner,
}: any = propertySlice.actions;

export default propertySlice.reducer;
