import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  userManagementFilters: {
    pageNo: 0,
    limit: 10,
    searchKey: '',
    status: '',
    sortBy: '',
    sortOrder: 0,
    fromDate: 0,
    toDate: 0,
    walletBalanceFrom: '',
    walletBalanceTo: '',
    isEmailVerified: '',
    isKYCVerified: '',
    amlCheckCompleted: '',
  },
  userWalletFilters: {
    userId: '',
    pageNo: 0,
    limit: 10,
    searchKey: '',
    status: '',
    sortBy: '',
    sortOrder: 0,
    type: '',
    category: '',
  },
  sliceWalletFilters: {
    pageNo: 0,
    limit: 10,
    searchKey: '',
    sortBy: '',
    sortOrder: '',
    type: '',
    status: '',
    category: '',
  },
  rolesFilters: {
    pageNo: 0,
    limit: 10,
    searchKey: '',
    sortBy: '',
    sortOrder: '',
    type: '',
    fromDate: '',
    toDate: '',
  },
  rolesSubadminFilters: {
    pageNo: 0,
    limit: 10,
    searchKey: '',
    sortBy: '',
    sortOrder: '',
    type: '',
    fromDate: '',
    toDate: '',
  },
  referralCouponfilters: {
    pageNo: 0,
    limit: 10,
    search: '',
    status: '',
    sortBy: '',
    sortOrder: '',
    type: '',
    startFrom: 0,
    endFrom: 0,
    startTo: 0,
    endTo: 0,
  },
  promotionCouponfilters: {
    pageNo: 0,
    limit: 10,
    search: '',
    status: '',
    sortBy: '',
    sortOrder: '',
    type: '',
    startFrom: 0,
    endFrom: 0,
    startTo: 0,
    endTo: 0,
  },
  externalAccountsFilters: {
    pageNo: 0,
    limit: 10,
    searchKey: '',
    sortBy: '',
    sortOrder: '',
    type: '',
  },
  externalAccountsTypesFilters: {
    pageNo: 0,
    limit: 10,
    searchKey: '',
    sortBy: '',
    sortOrder: '',
    type: '',
  },
  draftPropertyFilters: {
    pageNo: 0,
    limit: 10,
    searchKey: '',
    sortBy: '',
    sortOrder: 0,
    listingFromDate: 0,
    listingToDate: 0,
    fundingTargetFromDate: 0,
    fundingTargetToDate: 0,
    status: '',
    subscribedFrom: '',
    subscribedTo: '',
  },
  upcomingPropertyFilters: {
    pageNo: 0,
    limit: 10,
    searchKey: '',
    sortBy: '',
    sortOrder: 0,
    listingFromDate: 0,
    listingToDate: 0,
    fundingTargetFromDate: 0,
    fundingTargetToDate: 0,
    status: '',
    subscribedFrom: '',
    subscribedTo: '',
  },
  publishPropertyFilters: {
    pageNo: 0,
    limit: 10,
    searchKey: '',
    sortBy: '',
    sortOrder: 0,
    listingFromDate: 0,
    listingToDate: 0,
    fundingTargetFromDate: 0,
    fundingTargetToDate: 0,
    status: '',
    subscribedFrom: '',
    subscribedTo: '',
  },
  propertyWalletFilters: {
    propertyId: '',
    pageNo: 0,
    limit: 10,
    searchKey: '',
    sortBy: '',
    sortOrder: 0,
    type: '',
    category: '',
    status: '',
    fromDate: '',
    toDate: '',
  },
  justSliceFilters: {
    pageNo: 0,
    limit: 10,
    search: '',
    sortBy: '',
    sortOrder: 0,
    createFrom: 0,
    createTo: 0,
    modifiedFrom: 0,
    modifiedTo: 0,
    status: '',
  },
  sliceAmbassadorFilters: {
    pageNo: 0,
    limit: 10,
    search: '',
    sortBy: '',
    sortOrder: 0,
    createFrom: 0,
    createTo: 0,
    modifiedFrom: 0,
    modifiedTo: 0,
    status: '',
  },
  blogFilters: {
    pageNo: 0,
    limit: 10,
    search: '',
    sortBy: '',
    sortOrder: 0,
    createFrom: 0,
    createTo: 0,
    modifiedFrom: 0,
    modifiedTo: 0,
    status: '',
  },
  newsFilters: {
    pageNo: 0,
    limit: 10,
    search: '',
    sortBy: '',
    sortOrder: 0,
    createFrom: 0,
    createTo: 0,
    modifiedFrom: 0,
    modifiedTo: 0,
    status: '',
  },
  perksFilters: {
    pageNo: 0,
    limit: 10,
    search: '',
    sortBy: '',
    sortOrder: 0,
    createFrom: 0,
    createTo: 0,
    modifiedFrom: 0,
    modifiedTo: 0,
    status: '',
  },
  rewardsFilters: {
    pageNo: 0,
    limit: 10,
    search: '',
    sortBy: '',
    sortOrder: 0,
    createFrom: 0,
    createTo: 0,
    modifiedFrom: 0,
    modifiedTo: 0,
    status: '',
  },
  learnToInvestFilters: {
    pageNo: 0,
    limit: 10,
    search: '',
    sortBy: '',
    sortOrder: 0,
    createFrom: 0,
    createTo: 0,
    modifiedFrom: 0,
    modifiedTo: 0,
    status: '',
  },
  iconsFilters: {
    pageNo: 0,
    limit: 10,
    search: '',
    sortBy: '',
    sortOrder: 0,
    createFrom: 0,
    createTo: 0,
    modifiedFrom: 0,
    modifiedTo: 0,
    status: '',
  },
  faqFilters: {
    pageNo: 0,
    limit: 10,
    search: '',
    sortBy: '',
    sortOrder: 0,
    createFrom: 0,
    createTo: 0,
    modifiedFrom: 0,
    modifiedTo: 0,
    status: '',
  },
};
const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    userManagementFilterListner: (state: any, action: PayloadAction<any>) => {
      state.userManagementFilters.pageNo = action.payload.pageNo || 0;
      state.userManagementFilters.limit = action.payload.limit || 10;
      state.userManagementFilters.searchKey = action.payload.searchKey;
      state.userManagementFilters.status = action.payload.status;
      state.userManagementFilters.sortBy = action.payload.sortBy;
      state.userManagementFilters.sortOrder = action.payload.sortOrder;
      state.userManagementFilters.fromDate = action.payload.fromDate;
      state.userManagementFilters.toDate = action.payload.toDate;
      state.userManagementFilters.walletBalanceFrom = action.payload.walletBalanceFrom;
      state.userManagementFilters.walletBalanceTo = action.payload.walletBalanceTo;
      state.userManagementFilters.isEmailVerified = action.payload.isEmailVerified;
      state.userManagementFilters.isKYCVerified = action.payload.isKYCVerified;
      state.userManagementFilters.amlCheckCompleted = action.payload.amlCheckCompleted;
    },
    userWalletFilterListner: (state: any, action: PayloadAction<any>) => {
      state.userWalletFilters.userId = action.payload.userId || '';
      state.userWalletFilters.pageNo = action.payload.pageNo || 0;
      state.userWalletFilters.limit = action.payload.limit || 10;
      state.userWalletFilters.searchKey = action.payload.searchKey;
      state.userWalletFilters.status = action.payload.status;
      state.userWalletFilters.sortBy = action.payload.sortBy;
      state.userWalletFilters.sortOrder = action.payload.sortOrder;
      state.userWalletFilters.type = action.payload.type;
      state.userWalletFilters.category = action.payload.category;
    },
    sliceWalletFiltersListner: (state: any, action: PayloadAction<any>) => {
      state.sliceWalletFilters.pageNo = action.payload.pageNo || 0;
      state.sliceWalletFilters.limit = action.payload.limit || 10;
      state.sliceWalletFilters.searchKey = action.payload.searchKey;
      state.sliceWalletFilters.status = action.payload.status;
      state.sliceWalletFilters.sortBy = action.payload.sortBy;
      state.sliceWalletFilters.sortOrder = action.payload.sortOrder;
      state.sliceWalletFilters.type = action.payload.type;
      state.sliceWalletFilters.category = action.payload.category;
    },
    roleFiltersListner: (state: any, action: PayloadAction<any>) => {
      state.rolesFilters.pageNo = action.payload.pageNo || 0;
      state.rolesFilters.limit = action.payload.limit || 10;
      state.rolesFilters.searchKey = action.payload.searchKey;
      state.rolesFilters.type = action.payload.type;
      state.rolesFilters.sortBy = action.payload.sortBy;
      state.rolesFilters.sortOrder = action.payload.sortOrder;
      state.rolesFilters.fromDate = action.payload.fromDate;
      state.rolesFilters.toDate = action.payload.toDate;
    },
    roleSubFiltersListner: (state: any, action: PayloadAction<any>) => {
      state.rolesSubadminFilters.pageNo = action.payload.pageNo || 0;
      state.rolesSubadminFilters.limit = action.payload.limit || 10;
      state.rolesSubadminFilters.searchKey = action.payload.searchKey;
      state.rolesSubadminFilters.type = action.payload.type;
      state.rolesSubadminFilters.sortBy = action.payload.sortBy;
      state.rolesSubadminFilters.sortOrder = action.payload.sortOrder;
      state.rolesSubadminFilters.fromDate = action.payload.fromDate;
      state.rolesSubadminFilters.toDate = action.payload.toDate;
    },
    referralCouponFilterListner: (state: any, action: PayloadAction<any>) => {
      state.referralCouponfilters.sortBy = action.payload.sortBy;
      state.referralCouponfilters.sortOrder = action.payload.sortOrder;
      state.referralCouponfilters.limit = action.payload.limit;
      state.referralCouponfilters.pageNo = action.payload.pageNo;
      state.referralCouponfilters.type = action.payload.type;
      state.referralCouponfilters.search = action.payload.search;
      state.referralCouponfilters.status = action.payload.status;
      state.referralCouponfilters.startFrom = action.payload.startFrom;
      state.referralCouponfilters.startTo = action.payload.startTo;
      state.referralCouponfilters.endFrom = action.payload.endFrom;
      state.referralCouponfilters.endTo = action.payload.endTo;
    },
    promotionCouponFilterListner: (state: any, action: PayloadAction<any>) => {
      state.promotionCouponfilters.sortBy = action.payload.sortBy;
      state.promotionCouponfilters.sortOrder = action.payload.sortOrder;
      state.promotionCouponfilters.limit = action.payload.limit;
      state.promotionCouponfilters.pageNo = action.payload.pageNo;
      state.promotionCouponfilters.type = action.payload.type;
      state.promotionCouponfilters.search = action.payload.search;
      state.promotionCouponfilters.status = action.payload.status;
      state.promotionCouponfilters.startFrom = action.payload.startFrom;
      state.promotionCouponfilters.startTo = action.payload.startTo;
      state.promotionCouponfilters.endFrom = action.payload.endFrom;
      state.promotionCouponfilters.endTo = action.payload.endTo;
    },
    externalAccountFilterListner: (state: any, action: any) => {
      state.externalAccountsFilters.limit = action.payload.limit || 10;
      state.externalAccountsFilters.searchKey = action.payload.searchKey;
      state.externalAccountsFilters.sortBy = action.payload.sortBy;
      state.externalAccountsFilters.sortOrder = action.payload.sortOrder;
      state.externalAccountsFilters.pageNo = action.payload.pageNo || 0;
      state.externalAccountsFilters.type = action.payload.type;
    },
    externalAccountTypesFilterListner: (state: any, action: any) => {
      state.externalAccountsTypesFilters.limit = action.payload.limit || 10;
      state.externalAccountsTypesFilters.searchKey = action.payload.searchKey;
      state.externalAccountsTypesFilters.sortBy = action.payload.sortBy;
      state.externalAccountsTypesFilters.sortOrder = action.payload.sortOrder;
      state.externalAccountsTypesFilters.pageNo = action.payload.pageNo || 0;
      state.externalAccountsTypesFilters.type = action.payload.type;
    },
    draftPropertyFilterListner: (state: any, action: any) => {
      state.draftPropertyFilters.limit = action.payload.limit || 10;
      state.draftPropertyFilters.pageNo = action.payload.pageNo || 0;
      state.draftPropertyFilters.searchKey = action.payload.searchKey;
      state.draftPropertyFilters.sortBy = action.payload.sortBy;
      state.draftPropertyFilters.sortOrder = action.payload.sortOrder;
      state.draftPropertyFilters.listingFromDate = action.payload.listingFromDate;
      state.draftPropertyFilters.listingToDate = action.payload.listingToDate;
      state.draftPropertyFilters.fundingTargetFromDate = action.payload.fundingTargetFromDate;
      state.draftPropertyFilters.fundingTargetToDate = action.payload.fundingTargetToDate;
      state.draftPropertyFilters.status = action.payload.status;
      state.draftPropertyFilters.subscribedFrom = action.payload.subscribedFrom;
      state.draftPropertyFilters.subscribedTo = action.payload.subscribedTo;
    },
    upcomingPropertyFilterListner: (state: any, action: any) => {
      state.upcomingPropertyFilters.limit = action.payload.limit || 10;
      state.upcomingPropertyFilters.pageNo = action.payload.pageNo || 0;
      state.upcomingPropertyFilters.searchKey = action.payload.searchKey;
      state.upcomingPropertyFilters.sortBy = action.payload.sortBy;
      state.upcomingPropertyFilters.sortOrder = action.payload.sortOrder;
      state.upcomingPropertyFilters.listingFromDate = action.payload.listingFromDate;
      state.upcomingPropertyFilters.listingToDate = action.payload.listingToDate;
      state.upcomingPropertyFilters.fundingTargetFromDate = action.payload.fundingTargetFromDate;
      state.upcomingPropertyFilters.fundingTargetToDate = action.payload.fundingTargetToDate;
      state.upcomingPropertyFilters.status = action.payload.status;
      state.upcomingPropertyFilters.subscribedFrom = action.payload.subscribedFrom;
      state.upcomingPropertyFilters.subscribedTo = action.payload.subscribedTo;
    },
    publishPropertyFilterListner: (state: any, action: any) => {
      state.publishPropertyFilters.limit = action.payload.limit || 10;
      state.publishPropertyFilters.pageNo = action.payload.pageNo || 0;
      state.publishPropertyFilters.searchKey = action.payload.searchKey;
      state.publishPropertyFilters.sortBy = action.payload.sortBy;
      state.publishPropertyFilters.sortOrder = action.payload.sortOrder;
      state.publishPropertyFilters.listingFromDate = action.payload.listingFromDate;
      state.publishPropertyFilters.listingToDate = action.payload.listingToDate;
      state.publishPropertyFilters.fundingTargetFromDate = action.payload.fundingTargetFromDate;
      state.publishPropertyFilters.fundingTargetToDate = action.payload.fundingTargetToDate;
      state.publishPropertyFilters.status = action.payload.status;
      state.publishPropertyFilters.subscribedFrom = action.payload.subscribedFrom;
      state.publishPropertyFilters.subscribedTo = action.payload.subscribedTo;
    },
    propertyWalletTransactionFilters: (state: any, action: PayloadAction<any>) => {
      state.propertyWalletFilters.propertyId = action.payload.propertyId;
      state.propertyWalletFilters.limit = action.payload.limit || 10;
      state.propertyWalletFilters.pageNo = action.payload.pageNo || 0;
      state.propertyWalletFilters.searchKey = action.payload.searchKey;
      state.propertyWalletFilters.sortBy = action.payload.sortBy;
      state.propertyWalletFilters.sortOrder = action.payload.sortOrder;
      state.propertyWalletFilters.type = action.payload.type;
      state.propertyWalletFilters.category = action.payload.category;
      state.propertyWalletFilters.fromDate = action.payload.fromDate;
      state.propertyWalletFilters.toDate = action.payload.toDate;
      state.propertyWalletFilters.status = action.payload.status;
    },
    justSliceFiltersListner: (state: any, action: any) => {
      state.justSliceFilters.pageNo = action.payload.pageNo || 0;
      state.justSliceFilters.limit = action.payload.limit || 10;
      state.justSliceFilters.search = action.payload.search;
      state.justSliceFilters.status = action.payload.status;
      state.justSliceFilters.sortBy = action.payload.sortBy;
      state.justSliceFilters.sortOrder = action.payload.sortOrder;
      state.justSliceFilters.createFrom = action.payload.createFrom;
      state.justSliceFilters.createTo = action.payload.createTo;
      state.justSliceFilters.modifiedFrom = action.payload.modifiedFrom;
      state.justSliceFilters.modifiedTo = action.payload.modifiedTo;
    },
    sliceAmbassadorFiltersListner: (state: any, action: any) => {
      state.sliceAmbassadorFilters.pageNo = action.payload.pageNo || 0;
      state.sliceAmbassadorFilters.limit = action.payload.limit || 10;
      state.sliceAmbassadorFilters.search = action.payload.search;
      state.sliceAmbassadorFilters.status = action.payload.status;
      state.sliceAmbassadorFilters.sortBy = action.payload.sortBy;
      state.sliceAmbassadorFilters.sortOrder = action.payload.sortOrder;
      state.sliceAmbassadorFilters.createFrom = action.payload.createFrom;
      state.sliceAmbassadorFilters.createTo = action.payload.createTo;
      state.sliceAmbassadorFilters.modifiedFrom = action.payload.modifiedFrom;
      state.sliceAmbassadorFilters.modifiedTo = action.payload.modifiedTo;
    },
    blogFilterListner: (state: any, action: any) => {
      state.blogFilters.pageNo = action.payload.pageNo || 0;
      state.blogFilters.limit = action.payload.limit || 10;
      state.blogFilters.search = action.payload.search;
      state.blogFilters.status = action.payload.status;
      state.blogFilters.sortBy = action.payload.sortBy;
      state.blogFilters.sortOrder = action.payload.sortOrder;
      state.blogFilters.createFrom = action.payload.createFrom;
      state.blogFilters.createTo = action.payload.createTo;
      state.blogFilters.modifiedFrom = action.payload.modifiedFrom;
      state.blogFilters.modifiedTo = action.payload.modifiedTo;
    },
    newsFilterListner: (state: any, action: any) => {
      state.newsFilters.pageNo = action.payload.pageNo || 0;
      state.newsFilters.limit = action.payload.limit || 10;
      state.newsFilters.search = action.payload.search;
      state.newsFilters.status = action.payload.status;
      state.newsFilters.sortBy = action.payload.sortBy;
      state.newsFilters.sortOrder = action.payload.sortOrder;
      state.newsFilters.createFrom = action.payload.createFrom;
      state.newsFilters.createTo = action.payload.createTo;
      state.newsFilters.modifiedFrom = action.payload.modifiedFrom;
      state.newsFilters.modifiedTo = action.payload.modifiedTo;
    },
    perksFilterListner: (state: any, action: any) => {
      state.perksFilters.pageNo = action.payload.pageNo || 0;
      state.perksFilters.limit = action.payload.limit || 10;
      state.perksFilters.search = action.payload.search;
      state.perksFilters.status = action.payload.status;
      state.perksFilters.sortBy = action.payload.sortBy;
      state.perksFilters.sortOrder = action.payload.sortOrder;
      state.perksFilters.createFrom = action.payload.createFrom;
      state.perksFilters.createTo = action.payload.createTo;
      state.perksFilters.modifiedFrom = action.payload.modifiedFrom;
      state.perksFilters.modifiedTo = action.payload.modifiedTo;
    },
    rewardsFiltersListner: (state: any, action: any) => {
      state.rewardsFilters.pageNo = action.payload.pageNo || 0;
      state.rewardsFilters.limit = action.payload.limit || 10;
      state.rewardsFilters.search = action.payload.search;
      state.rewardsFilters.status = action.payload.status;
      state.rewardsFilters.sortBy = action.payload.sortBy;
      state.rewardsFilters.sortOrder = action.payload.sortOrder;
      state.rewardsFilters.createFrom = action.payload.createFrom;
      state.rewardsFilters.createTo = action.payload.createTo;
      state.rewardsFilters.modifiedFrom = action.payload.modifiedFrom;
      state.rewardsFilters.modifiedTo = action.payload.modifiedTo;
    },
    learnToInvestFiltersListner: (state: any, action: any) => {
      state.learnToInvestFilters.pageNo = action.payload.pageNo || 0;
      state.learnToInvestFilters.limit = action.payload.limit || 10;
      state.learnToInvestFilters.search = action.payload.search;
      state.learnToInvestFilters.status = action.payload.status;
      state.learnToInvestFilters.sortBy = action.payload.sortBy;
      state.learnToInvestFilters.sortOrder = action.payload.sortOrder;
      state.learnToInvestFilters.createFrom = action.payload.createFrom;
      state.learnToInvestFilters.createTo = action.payload.createTo;
      state.learnToInvestFilters.modifiedFrom = action.payload.modifiedFrom;
      state.learnToInvestFilters.modifiedTo = action.payload.modifiedTo;
    },
    iconsFiltersListner: (state: any, action: any) => {
      state.iconsFilters.pageNo = action.payload.pageNo || 0;
      state.iconsFilters.limit = action.payload.limit || 10;
      state.iconsFilters.search = action.payload.search;
      state.iconsFilters.status = action.payload.status;
      state.iconsFilters.sortBy = action.payload.sortBy;
      state.iconsFilters.sortOrder = action.payload.sortOrder;
      state.iconsFilters.createFrom = action.payload.createFrom;
      state.iconsFilters.createTo = action.payload.createTo;
      state.iconsFilters.modifiedFrom = action.payload.modifiedFrom;
      state.iconsFilters.modifiedTo = action.payload.modifiedTo;
    },
    faqFiltersListner: (state: any, action: any) => {
      state.faqFilters.pageNo = action.payload.pageNo || 0;
      state.faqFilters.limit = action.payload.limit || 10;
      state.faqFilters.search = action.payload.search;
      state.faqFilters.status = action.payload.status;
      state.faqFilters.sortBy = action.payload.sortBy;
      state.faqFilters.sortOrder = action.payload.sortOrder;
      state.faqFilters.createFrom = action.payload.createFrom;
      state.faqFilters.createTo = action.payload.createTo;
      state.faqFilters.modifiedFrom = action.payload.modifiedFrom;
      state.faqFilters.modifiedTo = action.payload.modifiedTo;
    },
    resetFilters: (state: any, action: PayloadAction<any>) => {
      return initialState;
    },
  },
});
export const {
  userManagementFilterListner,
  resetFilters,
  userWalletFilterListner,
  sliceWalletFiltersListner,
  roleFiltersListner,
  roleSubFiltersListner,
  promotionCouponFilterListner,
  referralCouponFilterListner,
  externalAccountFilterListner,
  externalAccountTypesFilterListner,
  draftPropertyFilterListner,
  upcomingPropertyFilterListner,
  publishPropertyFilterListner,
  propertyWalletTransactionFilters,
  justSliceFiltersListner,
  sliceAmbassadorFiltersListner,
  blogFilterListner,
  newsFilterListner,
  perksFilterListner,
  rewardsFiltersListner,
  learnToInvestFiltersListner,
  iconsFiltersListner,
  faqFiltersListner,
}: any = filterSlice.actions;

export default filterSlice.reducer;
