import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { UserManagementModel } from '../../Utils/model';

const initialState: UserManagementModel = {
  loading: true,
  data: [],
  error: '',
  total: 0,
  pageNo: 0,
  limit: 0,
  totalPage: 0,
  key: [],
  userWalletData: {
    data: [],
    error: '',
    total: 0,
    pageNo: 0,
    limit: 0,
    totalPage: 0,
    capitalAppreciation: 0,
    investmentAmount: 0,
    noOfInvestedProperties: 0,
    portfolioValue: 0,
    rentRecieved: 0,
    walletBalance: 0,
    isKYCVerified: false,
  },
};
const userListSlice = createSlice({
  name: 'userList',
  initialState,
  reducers: {
    loaderListner: (state: any, action: any) => {
      state.loading = action.payload.loading;
    },
    successUserListReducer: (state: any, action: PayloadAction<any>) => {
      state.loading = action.payload.loading;
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.limit = action.payload.limit;
      state.pageNo = action.payload.pageNo;
      state.totalPage = action.payload.totalPage;
      state.key = action.payload.key;
    },
    failedUserListReducer: (state: any, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    userWalletListner: (state: any, action: PayloadAction<any>) => {
      state.loading = action.payload.loading;
      state.userWalletData.isKYCVerified = action.payload.isKYCVerified;
      state.userWalletData.capitalAppreciation = action.payload.capitalAppreciation;
      state.userWalletData.investmentAmount = action.payload.investmentAmount;
      state.userWalletData.noOfInvestedProperties = action.payload.noOfInvestedProperties;
      state.userWalletData.rentRecieved = action.payload.rentRecieved;
      state.userWalletData.walletBalance = action.payload.walletBalance;
      state.userWalletData.portfolioValue = action.payload.portfolioValue;
      state.userWalletData.data = action.payload.data;
      state.userWalletData.total = action.payload.total;
      state.userWalletData.limit = action.payload.limit;
      state.userWalletData.pageNo = action.payload.pageNo;
      state.userWalletData.totalPage = action.payload.totalPage;
    },
  },
});
export const {
  successUserListReducer,
  failedUserListReducer,
  loaderListner,
  userWalletListner,
}: any = userListSlice.actions;

export default userListSlice.reducer;
