import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  propertyWalletListData: {
    loading: true,
    data: [],
    total: 0,
    limit: 0,
    pageNo: 0,
    totalPage: 0,
  },
  calculateDividendData: {
    loading: true,
    data: [],
    total: 0,
    limit: 0,
    pageNo: 0,
    totalPage: 0,
  },
  calculatefinalSettlementData: {
    loading: true,
    data: [],
    total: 0,
    limit: 0,
    pageNo: 0,
    totalPage: 0,
  },
  walletBalance: 0,
  propertyWalletBalance: 0,
  propertyWalletTransactionDetailData: {},
  pendingTransactionsNumber: 0,
};

const propertyWalletSlice = createSlice({
  name: 'propertyWallet',
  initialState,
  reducers: {
    fetchPropertyWalletList: (state: any, action: PayloadAction<any>) => {
      state.propertyWalletListData.loading = action.payload.loading;
    },
    propertyWalletListSuccess: (state: any, action: PayloadAction<any>) => {
      state.propertyWalletListData.loading = false;
      state.propertyWalletListData.data = action.payload.data;
      state.propertyWalletListData.total = action.payload.total;
      state.propertyWalletListData.limit = action.payload.limit;
      state.propertyWalletListData.pageNo = action.payload.pageNo;
      state.propertyWalletListData.totalPage = action.payload.totalPage;
    },
    propertyWalletTransactionDetail: (state: any, action: PayloadAction<any>) => {
      state.propertyWalletTransactionDetailData = action.payload.data;
    },
    getWalletBalanceSlice: (state: any, action: PayloadAction<any>) => {
      state.walletBalance = action.payload.data;
    },
    getPropertyWalletBalanceSlice: (state: any, action: PayloadAction<any>) => {
      state.propertyWalletBalance = action.payload.data;
    },
    calculateDividendListner: (state: any, action: PayloadAction<any>) => {
      state.calculateDividendData.loading = false;
      state.calculateDividendData.data = action.payload.data;
      state.calculateDividendData.total = action.payload.total;
      state.calculateDividendData.limit = action.payload.limit;
      state.calculateDividendData.pageNo = action.payload.pageNo;
      state.calculateDividendData.totalPage = action.payload.totalPage;
    },
    calculateFinalSettlementListner: (state: any, action: PayloadAction<any>) => {
      state.calculatefinalSettlementData.loading = false;
      state.calculatefinalSettlementData.data = action.payload.data;
      state.calculatefinalSettlementData.total = action.payload.total;
      state.calculatefinalSettlementData.limit = action.payload.limit;
      state.calculatefinalSettlementData.pageNo = action.payload.pageNo;
      state.calculatefinalSettlementData.totalPage = action.payload.totalPage;
    },
    getPendingTransactionSlice: (state: any, action: PayloadAction<any>) => {
      state.pendingTransactionsNumber = action.payload.data;
    },
  },
});

export const {
  fetchPropertyWalletList,
  propertyWalletListSuccess,
  propertyWalletTransactionDetail,
  getWalletBalanceSlice,
  getPropertyWalletBalanceSlice,
  calculateDividendListner,
  calculateFinalSettlementListner,
  getPendingTransactionSlice,
}: any = propertyWalletSlice.actions;
export default propertyWalletSlice.reducer;
