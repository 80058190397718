const EndPoint = {
  login: '/login',
  forgotPassword: '/forgot-password',
  resendEmail: '/resend-Email',
  resetPassword: '/reset-password',
  userManagement: '/users',
  blockUser: '/users/status',
  userDetail: '/users',
  addProperty: '/properties',
  propertyManagement: '/properties/listing',
  userFilter: '/users/filter',
  propertyStatus: '/properties/status',
  idVerificationStatus: '/users/idVerificationStatus',
  propertyDetail: '/properties/',
  amlStatusCheck: '/users/amlStatus',
  addressVerificationStatus: '/users/addressVerificationStatus',
  emailStatus: '/users/emailStatus',
  userManagementWallet: '/user/wallet_transaction',
  transactionDetail: '/userWallet',
  propertyWalletTransactionDetail: '/properties/wallet',
  transaction: '/transaction',
  searchUserByEmail: '/search_user_email',
  searchPropertyName: '/search_property_name',
  userWalletStatus: '/userWallet/markAsCompletedOrFailed',
  userWalletCompletedManyStatus: '/userWallet/markAsCompletedUserWallet',
  userWalletFailedManyStatus: '/userWallet/markAsFailedUserWallet',
  propertyWalletStatus: '/properties/wallet/mark_as_completed',
  favouriteList: '/properties/favourite_list',
  userWallet: '/userWalletList',
  propertyWallet: '/properties/wallet',
  spvAccount: '/spvAccount',
  propertyManagementTransaction: '/properties/wallet_transaction',
  settleTransaction: '/settleTransactions',
  updateSPVAccount: '/updateSPVAccount',
  updateCurrentValue: '/properties/present-value',
  deleteCurrentValue: '/properties/delete-present-value',
  rollback: '/rollback-settlement',
  addExternalAccount: '/externalAccount',
  editExternalAccount: '/externalAccount',
  externalAccountListing: '/externalAccount/listing',
  externalAccounType: '/externalAccountType',
  externalAccountDetail: '/externalAccount/',
  externalAccountType: '/externalAccountType',
  externalAccountTypeListing: '/externalAccountType/listing',
  addTranscationPropertyWallet: '/properties/transaction',
  typeNameSearch: '/search_external_account_names',
  userWalletExport: '/export/user/user-wallet-transaction-List',
  propertyManagementWalletExport: '/export/property/property-wallet-transaction-List',
  propertyWalletMarkAsFailed: '/properties/wallet/mark_as_failed',
  export: '/exports',
  walletBalance: '/availablePropertyWalletBalance',
  propertyWalletBalance: '/properties/property_walletBalance',
  calculateDividend: '/properties/calculateDividend',
  dividendDetails: '/dividendDetails',
  calculateFinalSettlement: '/properties/calculateFinalSettlement',
  settlementDetails: '/settlementDetails',
  justSlice: '/content/slice-things',
  dashboardnewusers: '/dashboard/newUsers',
  dashboardpropertiesDetails: '/dashboard/propertiesDetails',
  dashboardportfolioDetails: '/dashboard/portfolioInvestedProperties',
  dashboardportfolioCalculatedDetails: '/dashboard/portfolioCalulatedValues',
  dashboardUserDetails: '/dashboard/userDetails',
  couponList: '/coupon/promotion',
  couponPromotion: '/excel-read',
  sliceAmbassador: '/content/slice-ambassador',
  userWalletBalance: '/availableUserWalletBalance',
  perksOfInvestment: '/content/slice-perksOf-investment',
  slicenews: '/content/slice-news',
  rewardsAndGrowMore: '/content/slice-rewardsAnd-grow-more',
  promotionDetail: '/coupon/promotion/',
  promotionStatus: '/coupon/prmotions/status',
  addReferralCoupon: '/coupon/referral',
  referralCoupon: '/coupon/referral',
  referralStatus: '/coupon/referrals/status',
  downloadTemplate: '/download-template',
  pendingTransaction: '/properties/pendingTransactions',
  loginScreens: '/content/slice-login-screens',
  legalContent: '/content/slice-legal-content',
  cmsIcons: '/content/slice-icons',
  sliceWallet: 'slice-wallet/list',
  sliceWalletTransactionDetail: '/slice-wallet',
  sliceWalletTransactionCompleteOrFailed: '/slice-wallet/mark_as_completed-or-Failed',
  addTransactionSliceWallet: '/slice-wallet/transaction',
  editAmount: '/userWallet/payinAmount',
  faqCategory: '/content/category/slice-FAQ',
  faq: '/content/slice-FAQ',
  blog: '/content/slice-blogs',
  learnToInvest: '/content/slice-learn-to-invest',
  support: '/content/slice-support',
  expiryYear: '/keys',
  sumsubImage: '/sumsub/image',
  profile: '/profile',
  changePassword: '/change-password',
  kycHistory: '/kyc/history',
  permission: '/permissions',
  roleList: '/role',
  addSubadmin: '/subadmin',
  changeRoleStatus: '/role/active-inactive',
  changeSubAdminStatus: '/subadmin/block-unblock',
};
export default EndPoint;
