//@ts-ignore
import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  data: [],
  loading: false,
};
const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    loaderListner: (state: any, action: any) => {
      state.loading = action.payload.loading;
    },
    supportListner: (state: any, action: any) => {
      state.loading = action.payload.loading;
      state.data = action.payload.data;
    },
  },
});
export const { loaderListner, supportListner }: any = supportSlice.actions;

export default supportSlice.reducer;
