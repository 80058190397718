import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
};
const blockUserSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loading: (state: any, action: any) => {
      state.loading = action.payload.loading
   }
  },
});

// Action creators are generated for each case reducer function
export const { loading } : any = blockUserSlice.actions;

export default blockUserSlice.reducer;
