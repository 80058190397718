//@ts-ignore
import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  newUserData: null,
  propertiesDetailsData: null,
  portfolioDetailsData: null,
  portfolioCalculateDetailData: null,
  userDetailsData: null,
  loading: false,
};
const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    loaderListner: (state: any, action: any) => {
      state.loading = action.payload.loading;
    },
    newUserListner: (state: any, action: any) => {
      state.newUserData = action.payload.data;
    },
    propertiesDetailsListner: (state: any, action: any) => {
      state.propertiesDetailsData = action.payload.data;
    },
    userDetailsListner: (state: any, action: any) => {
      state.userDetailsData = action.payload.data;
    },
    portfolioDetailsListner: (state: any, action: any) => {
      state.portfolioDetailsData = action.payload.data;
    },
    portfolioCalculateDetailsListner: (state: any, action: any) => {
      state.portfolioCalculateDetailData = action.payload.data;
    },
  },
});
export const {
  loaderListner,
  newUserListner,
  propertiesDetailsListner,
  portfolioDetailsListner,
  portfolioCalculateDetailsListner,
  userDetailsListner,
}: any = dashboardSlice.actions;

export default dashboardSlice.reducer;
