import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { LoginModel } from '../../Utils/model';

const initialState: LoginModel = {
  data: {},
};
const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    signin: (state: LoginModel, action: PayloadAction<LoginModel>) => {
      state.data = action.payload.data;
    },
  },
});
// Action creators are generated for each case reducer function
export const { signin }: any = loginSlice.actions;

export default loginSlice.reducer;
