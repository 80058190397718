//@ts-ignore

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  data: [],
  error: null,
  total: 0,
  pageNo: 0,
  limit: 0,
  totalPage: 0,
  loading: false,
  promotionDetailData: {},
  referralDetailData: {},

  referralCoupon: {
    globalUser: [],
    userSpecific: {},
  },
};
const couponManagementSlice = createSlice({
  name: 'couponManagement',
  initialState,
  reducers: {
    loaderListner: (state: any, action: any) => {
      state.loading = action.payload.loading;
    },
    promontionListListner: (state: any, action: PayloadAction<any>) => {
      state.loading = action.payload.loading;
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.limit = action.payload.limit;
      state.pageNo = action.payload.pageNo;
      state.totalPage = action.payload.totalPage;
    },
    promotionDetail: (state: any, action: PayloadAction<any>) => {
      state.promotionDetailData = action.payload.data;
    },
    referralCouponData: (state: any, action: PayloadAction<any>) => {
      state.referralCoupon.globalUser = action.payload.globalUser;
      state.referralCoupon.userSpecific = action.payload.userSpecific;
    },
    referrralDetail: (state: any, action: PayloadAction<any>) => {
      state.referralDetailData = action.payload.data;
    },
  },
});
export const {
  loaderListner,
  promontionListListner,
  promotionDetail,
  referrralDetail,
  referralCouponData,
}: any = couponManagementSlice.actions;

export default couponManagementSlice.reducer;
