import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ForgotModel } from '../../Utils/model';

const initialState: ForgotModel = {
  email: '',
};
const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    forgotPasswordReducer: (state: ForgotModel, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
  },
});
export const { forgotPasswordReducer }: any = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
