//@ts-ignore
import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  data: [],
  error: null,
  total: 0,
  pageNo: 0,
  limit: 0,
  totalPage: 0,
  loading: false,
  accountDetailData: {},
  typeList: [],
  externalAccountTypeList: {
    data: [],
    error: null,
    total: 0,
    pageNo: 0,
    limit: 0,
    totalPage: 0,
  },

};
const externalAccountSlice = createSlice({
  name: 'externalAccount',
  initialState,
  reducers: {
    loaderListner: (state: any, action: any) => {
      state.loading = action.payload.loading;
    },
    externalAccountListner: (state: any, action: any) => {
      state.loading = action.payload.loading;
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.limit = action.payload.limit;
      state.pageNo = action.payload.pageNo;
      state.totalPage = action.payload.totalPage;
    },
    externalAccountTypeListner: (state: any, action: any) => {
      state.externalAccountTypeList.data = action.payload.data;
      state.externalAccountTypeList.total = action.payload.total;
      state.externalAccountTypeList.limit = action.payload.limit;
      state.externalAccountTypeList.pageNo = action.payload.pageNo;
      state.externalAccountTypeList.totalPage = action.payload.totalPage;
    },
    externalAccountDetail: (state: any, action: any) => {
      state.accountDetailData = action.payload.data;
    },
    externalTypeList: (state: any, action: any) => {
      state.typeList = action.payload.data;
    },

  },
});
export const {
  loaderListner,
  externalAccountListner,
  externalAccountDetail,
  externalTypeList,
  externalAccountTypeListner,

}: any = externalAccountSlice.actions;

export default externalAccountSlice.reducer;
