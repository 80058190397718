//@ts-ignore
import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  data: [],
  loading: false,
  passwordData: [],
};
const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    loaderListner: (state: any, action: any) => {
      state.loading = action.payload.loading;
    },
    profileListner: (state: any, action: any) => {
      state.data = action.payload.data;
    },
    changePasswordListner: (state: any, action: any) => {
      state.data = action.payload.passwordData;
    },  
  },
});
export const { loaderListner, profileListner, changePasswordListner }: any = profileSlice.actions;

export default profileSlice.reducer;
