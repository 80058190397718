//@ts-ignore

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  data: [],
  error: null,
  total: 0,
  pageNo: 0,
  limit: 0,
  totalPage: 0,
  loading: false,
  transactionDetailData: [],
  sliceWalletValues: {},
};

const sliceWalletSlice = createSlice({
  name: 'sliceWallet',
  initialState,
  reducers: {
    loaderListner: (state: any, action: any) => {
      state.loading = action.payload.loading;
    },
    sliceWalletListner: (state: any, action: PayloadAction<any>) => {
      state.loading = action.payload.loading;
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.limit = action.payload.limit;
      state.pageNo = action.payload.pageNo;
      state.totalPage = action.payload.totalPage;
      state.sliceWalletValues = action.payload.sliceWalletValues;
    },
    transactionDetailSlice: (state: any, action: PayloadAction<any>) => {
      state.transactionDetailData = action.payload.data;
    },

  },
});
export const {
  loaderListner,
  sliceWalletListner,
  transactionDetailSlice,
}: any = sliceWalletSlice.actions;

export default sliceWalletSlice.reducer;
