import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  data: [],
  loading: false,
  favoriteData: [],
  currentValue: [],
};
const propertyDetailSlice = createSlice({
  name: 'propertydetail',
  initialState,
  reducers: {
    loaderListner: (state: any, action: PayloadAction<any>) => {
 state.loading = action.payload.loading;
    },
    detail: (state: any, action: PayloadAction<any>) => {
      state.data = action.payload.data;
    },
    favoriteList: (state: any, action: PayloadAction<any>) => {
      state.favoriteData = action.payload.favoriteData;
    },
    updateCurrentValue: (state: any, action: PayloadAction<any>) => {
      state.currentValue = action.payload.currentValue;
    },
  },
});

// Action creators are generated for each case reducer function
export const { detail, favoriteList, updateCurrentValue, loaderListner }: any =
  propertyDetailSlice.actions;

export default propertyDetailSlice.reducer;
