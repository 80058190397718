//@ts-ignore

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  data: [],
  permissionData: [],
  subAdminData: {
    data: [],
    total: 0,
    pageNo: 0,
    limit: 0,
    totalPage: 0,
    subAdminDetailData: {},
  },
  roleDetailData: {},
  total: 0,
  pageNo: 0,
  limit: 0,
  totalPage: 0,
  loading: false,
};
const roleManagementSlice = createSlice({
  name: 'roleManagement',
  initialState,
  reducers: {
    loaderListner: (state: any, action: any) => {
      state.loading = action.payload.loading;
    },
    roleListListner: (state: any, action: PayloadAction<any>) => {
      state.loading = action.payload.loading;
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.limit = action.payload.limit;
      state.pageNo = action.payload.pageNo;
      state.totalPage = action.payload.totalPage;
    },
    subAdminListListner: (state: any, action: PayloadAction<any>) => {
      state.loading = action.payload.loading;
      state.subAdminData.data = action.payload.data;
      state.subAdminData.total = action.payload.total;
      state.subAdminData.limit = action.payload.limit;
      state.subAdminData.pageNo = action.payload.pageNo;
      state.subAdminData.totalPage = action.payload.totalPage;
    },
    permissionListner: (state: any, action: PayloadAction<any>) => {
      state.permissionData = action.payload.data;
    },
    subAdminDetailListner: (state: any, action: PayloadAction<any>) => {
      state.subAdminData.subAdminDetailData = action.payload.data;
    },
    roleDetailListner: (state: any, action: PayloadAction<any>) => {
      state.roleDetailData = action.payload.data;
    },

  },
});
export const {
  loaderListner,
  roleListListner,
  permissionListner,
  subAdminListListner,
  subAdminDetailListner,
  roleDetailListner,
}: any = roleManagementSlice.actions;

export default roleManagementSlice.reducer;
