//@ts-ignore
import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  data: [],
  total: 0,
  pageNo: 0,
  limit: 0,
  totalPage: 0,
  loading: false,
  filters: {
    pageNo: 0,
    limit: 10,
    searchKey: '',
    sortBy: '',
    sortOrder: '',
    type: '',
    status: '',
    category: '',
  },
};
const learnToInvestSlice = createSlice({
  name: 'learnToInvest',
  initialState,
  reducers: {
    loaderListner: (state: any, action: any) => {
      state.loading = action.payload.loading;
    },
    learnToInvestListner: (state: any, action: any) => {
      state.loading = action.payload.loading;
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.limit = action.payload.limit;
      state.pageNo = action.payload.pageNo;
      state.totalPage = action.payload.totalPage;
    },

  },
});
export const { loaderListner, learnToInvestListner }: any =
  learnToInvestSlice.actions;

export default learnToInvestSlice.reducer;
